import React from "react";
import Link from "next/link";
import dayjs from "dayjs";
import Style from "./style.module.scss";
import classNames from "classnames";
import { Separator } from "../ui/separator";
import { cn } from "~/lib/utils";

class Card extends React.Component {
  handleConfirm = () => {
    const { onDelete, data } = this.props;
    if (onDelete) {
      onDelete(data.id);
    }
  };

  renderMetaInfo = () => {
    const { data } = this.props;
    return (
      <>
          <span>
            发布于:&nbsp;
            <time
              dateTime={dayjs(data.createTime).format("YYYY-MM-DD HH:mm:ss")}
            >
              {dayjs(data.createTime).format("YYYY-MM-DD HH:mm")}
            </time>
          </span>
          <Separator orientation="vertical" className="mx-2"/>
          <span>{`标签: ${data.tags.join("，")}`}</span>
      </>
    );
  };

  render() {
    const { data } = this.props;
    return (
      <article
        className={classNames(Style.card, "p-4 md:px-8 md:py-6")}
        style={this.props.style || {}}
      >
        <a
          href={data.url ? data.url : `/archive/${data.id}`}
          title={data.name}
          rel="bookmark"
          target={data.url ? "_blank" : undefined}
        >
          {data.headerImg ? (
            <img
              className={classNames(Style["repo-pic"], "hidden sm:block")}
              src={`${data.headerImg}!/fh/114`}
              alt={data.name}
              loading="lazy"
            />
          ) : null}
          <div className={Style["repo-header-wrap"]}>
            <div className={Style["repo-header"]}>
              <h1 className={Style["repo-title"]} title={data.name}>
                {data.name}
              </h1>
            </div>
            <p className={Style["repo-desc"]}>
              {data.description || data.content.substr(0, 400)}
            </p>
            <div className={Style["repo-meta"]}>
              <div className={cn(Style["info-block"], "flex h-4")}>{this.renderMetaInfo()}</div>
              <div>
                <span className={Style["info-block"]}></span>
              </div>
            </div>
          </div>
        </a>
      </article>
    );
  }
}

export default Card;
