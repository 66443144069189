import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from "@/components/ui/pagination";
import { Button } from "../ui/button";
import { ReactNode } from "react";

interface Props {
  pathPrefix: string;
  current?: number;
  total?: number;
  pageSize?: number;
}

const Item = ({
  disabled,
  children,
  href,
}: {
  disabled?: boolean;
  children: ReactNode;
  href: string;
}) => {
  if (disabled) {
    return (
      <Button variant="outline" disabled={disabled}>
        {children}
      </Button>
    );
  }

  return (
    <a href={href}>
      <Button variant="outline">{children}</Button>
    </a>
  );
};

const MyPagination = (props: Props) => {
  const { current = 1, total = 0, pageSize = 10, pathPrefix } = props;
  if (total <= 1) {
    return null;
  }

  const hasPrev = current > 1;
  const hasNext = pageSize * current < total;
  return (
    <div className="w-full flex justify-between">
      <Item href={`/${pathPrefix}/${Number(current) - 1}`} disabled={!hasPrev}>
        上一页
      </Item>
      <Item href={`/${pathPrefix}/${Number(current) + 1}`} disabled={!hasNext}>
        下一页
      </Item>
    </div>
  );
};

export default MyPagination;
